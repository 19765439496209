<template>
  <div id="main" class="h-100">
    <div class="header w-100"></div>
    <div class="h-100 d-flex flex-column justify-content-center align-items-center">
      <div class="content p-5 text-center">
        <img class="w-75 mb-5" src="./images/kugihan-logo-v2.gif" alt="Kugihan Logo">
        <div class="mt-5">
          <h2>Website is currently under construction</h2>
          <form name="live-subscription" method="post" data-netlify="true" data-netlify-honeypot="bot-field" class="m-auto p-4 rounded d-none">
            <input type="hidden" name="form-name" value="live-subscription" />
            <div class="mb-3">Be notified when we are live</div>
            <div class="form-group d-flex">
              <input type="text" name="email" class="form-control mr-2" placeholder="Email Address" autofocus v-model="email">
              <button class="btn btn-danger d-block" type="submit">Submit</button>
            </div>
          </form>
        </div>
        <div class="mt-3">
          Kugihan Placement Agency Corp. &copy; 2019
        </div>
      </div>
    </div>
    <div class="footer w-100"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      email: ''
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Muli&display=swap');
@import '../node_modules/bootstrap/scss/bootstrap';

html, body {
  font-family: 'Muli', sans-serif;
  height: 100%;
}

form {
  max-width: 370px;
  min-width: 250px;
}

.header, .footer {
  background-size: cover;
  background-position: center;
  height: 125px;
  position: fixed;
  left: 0;
  z-index: -1;
}

.header {
  background-image: url('./images/kugihan-bg-1.jpg');
  top: 0;
}

.footer {
  background-image: url('./images/kugihan-bg-2.jpg');
  bottom: 0;
}

@media only screen and (min-width: 768px) {
  .header, .footer {
    height: 200px;
  }
}
</style>